import React from "react";

import GetWebpFormat from "../utils/getWebpFormat";
import ResponsiveImage from "./responsiveImage";

const ListenerUspMedia = (url, altText) => {
  const extension = url.substr(url.lastIndexOf('.') + 1);

  if (extension === 'mp4') {
    return (
      <picture>
        <video autoplay="true" loop muted>
          <source src={GetWebpFormat(url)} type="video/mp4" />
        </video>
      </picture>
    )
  }

  return <ResponsiveImage src={url} alt={altText} />
}

export default ListenerUspMedia;
