import React, { useEffect } from "react";

import "aos/dist/aos.css";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/seo";

import ResponsiveImage from "../components/responsiveImage";
import ListenerUspMedia from "../components/listenerUspMedia";
import NotFoundPage from "./404";

import GetWebpFormat from "../utils/getWebpFormat";

const WhyListenersPage = (context) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const AOS = require("aos");
    AOS.init({
      duration: 1800,
      once: true
    });

    if (AOS) {
      AOS.refresh();
    }

    const videoPoster = document.querySelector('.js-videoPoster');

    if (videoPoster) {
      document.querySelector('.js-videoPoster').onclick = ((event) => {
        event.preventDefault();

        const $currentElement = event.target.closest('.js-videoPoster');
        videoPlay($currentElement.closest('.js-videoWrapper'));
      });
    }

    if (AOS) {
      AOS.refresh();
    }
  }, []);

  function videoPlay($wrapper) {
    const $iframe = $wrapper.querySelector('.js-videoIframe');

    $wrapper.classList.add('videoWrapperActive');
    $iframe.setAttribute('src', $iframe.dataset['src'])
  }

  const whyListenerPageConfigs = context.pageContext.node;
  const headerData = context.pageContext.headerConfigurations;
  const footerData = context.pageContext.footerConfigurations;

  if (!whyListenerPageConfigs) return <NotFoundPage />;

  return (
    <Layout headerData={headerData} footerData={footerData}>
      { whyListenerPageConfigs.seo && <SEO page={`why-listener/${whyListenerPageConfigs.slug}`} seo={whyListenerPageConfigs.seo} /> }

      <section className="why-listener-hero-section">
        <div className={`background-layer ${whyListenerPageConfigs.hero_section.hero_image ? '' : 'has-no-hero-image'}`}>
          <div data-aos="animate-pink-triangle" className="pink-triangle"></div>
          <div data-aos="animate-stripes-for-pink-triangle" className="stripes-for-pink-triangle"></div>
          <div data-aos="animate-muti-line-triangles" className="muti-line-triangles"></div>
          <div data-aos="animate-why-listener-triangle-pattern-sand" className="triangle-pattern-sand"></div>
        </div>

        <div className="content-layer">
          <h1 className="h1" data-aos="animate-reveal">{whyListenerPageConfigs.hero_section.section_heading}</h1>
          <p className={`h2 ${whyListenerPageConfigs.hero_section.hero_image ? '' : 'has-no-hero-image'}`} data-aos="animate-reveal">{whyListenerPageConfigs.hero_section.section_body}</p>

          {
            whyListenerPageConfigs.hero_section.hero_image &&
            <div data-aos="animate-reveal" className="video-wrapper videoWrapper169 js-videoWrapper">
              <ResponsiveImage src={whyListenerPageConfigs.hero_section.hero_image.url} />
            </div>
          }
        </div>
      </section>

      <section className="why-listener-features-section">
        <ul className="feature-list">
          {
            whyListenerPageConfigs.why_listener_usps.listener_usps.map((listenerUspSection, index) => {
              return (
                <li key={index}>
                  <div className="feature">
                    <div className="feature-content">
                      <h4>{listenerUspSection.heading}</h4>
                      <p>{listenerUspSection.description}</p>
                      {
                        listenerUspSection.link_text &&
                        <a href={listenerUspSection.url} target={listenerUspSection.target}>
                          {listenerUspSection.link_text}
                        </a>
                      }
                    </div>

                    <div className="media-content">
                      { listenerUspSection.media &&
                        <div className="image-wrapper">
                          {ListenerUspMedia(listenerUspSection.media.url, listenerUspSection.heading)}
                        </div>
                      }
                      <div className="feature-background"></div>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </section>

      { whyListenerPageConfigs.testimonials &&
        <section className="testimonials-section">
          <div className="background-layer" role="presentation">
            <img className="stripes" alt="stripes" src="https://res.cloudinary.com/listener-cms/image/upload/f_auto/v1609144189/beige_rectangle_background_obfa8t.svg" />
          </div>

          <div className="content-layer">
            <h4>{whyListenerPageConfigs.testimonials.section_heading}</h4>

            <div className="testimonial-videos">
              {
                whyListenerPageConfigs.testimonials.testimonial_videos.map((testimonial, index) => {
                  return (
                    <div className="video-wrapper videoWrapper169 js-videoWrapper" key={`testimonial_${index}`}>
                      <iframe className="videoIframe js-videoIframe"
                        title="testimonial-video"
                        src=""
                        frameBorder="0"
                        allowtransparency="true"
                        autoPlay={true}
                        allowFullScreen
                        allow="autoplay"
                        data-src={`https://www.youtube.com/embed/${testimonial.video_id}?autoplay=1&modestbranding=1&rel=0`}
                      ></iframe>

                      <button className="videoPoster js-videoPoster"
                        aria-label="Video Background Image"
                        style={{backgroundImage: `url(${GetWebpFormat(testimonial.cover_image_desktop.url)})`}}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="159.933" height="159.933" viewBox="0 0 159.933 159.933"><g transform="translate(-520 -258)"><circle opacity="0.6" cx="79.967" cy="79.967" r="79.967" transform="translate(520 258)" /><path fill="#fff" d="M111.652,76.751,63.974,50.162a2.086,2.086,0,0,0-3.1,1.821v53.849a2.086,2.086,0,0,0,3.1,1.821L111.65,81.12a2.5,2.5,0,0,0,0-4.369" transform="translate(519 259.084)"/></g></svg>
                      </button>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </section>
      }

      <section className="link-cloud–section">
        <div className="content-layer">
          <h3 data-aos="animate-reveal" className="h4">{whyListenerPageConfigs.more_links_section.heading}</h3>
          <ul>
            {
              whyListenerPageConfigs.more_links_section.links.map((link, index) => {
                return (
                  <li key={`more-links-${index}`} data-aos="animate-reveal" data-aos-delay={`${index * 100}`}>
                    <a className="cloud-link" href={link.url} target={link.target}>
                      <span>{link.text}</span>
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </div>

        <div className="background-layer" role="presentation">
          <img data-aos="animate-triangle-pattern-sand" data-aos-offset="300" className="triangle-pattern-sand" alt="triangle-pattern-sand" src="https://res.cloudinary.com/listener-cms/image/upload/f_auto/v1608802011/triangle-pattern-sand_hax8s2.svg" />
        </div>
      </section>

      {
        whyListenerPageConfigs.cta_section &&
        <section className="cta-jumbo-section">
          <h5>{whyListenerPageConfigs.cta_section.caption}</h5>
          <a href={whyListenerPageConfigs.cta_section.url} className="cta-button" target={whyListenerPageConfigs.cta_section.target}>
            {whyListenerPageConfigs.cta_section.button_text}
          </a>
        </section>
      }

    </Layout>
  )
}

export default WhyListenersPage;
